import "@arco-design/web-react/es/Notification/style";
import _Notification from "@arco-design/web-react/es/Notification";
import axios from 'axios';
import { getToken, setToken } from '@/utils/auth';
const errMsg = {
  401: '登录过期，请重新登录'
};
axios.interceptors.request.use(config => {
  config.baseURL = '/api';
  config.headers = {
    Authorization: `${getToken()}`
  };
  return config;
}, error => {
  // do something
  return Promise.reject(error);
});
// add response interceptors
axios.interceptors.response.use(async response => {
  const res = response.data;
  if (res.code !== 200) {
    _Notification.error({
      title: '请求错误',
      content: errMsg[res.code] || res.data || '服务繁忙'
    });
    if (res.code === 401) {
      setToken('');
    }
    return Promise.reject();
  }
  return res;
}, error => {
  const status = error.response && error.response.status && error.response.status || '';
  switch (status) {
    case 401:
      error.msg = '登录超时';
      setToken('');
      break;
    case 404:
      error.msg = '服务繁忙';
      break;
    case 500:
      error.msg = '服务繁忙';
      break;
    default:
    //
  }
  _Notification.error({
    title: '请求错误',
    content: error.msg
  });
  return Promise.reject(error);
});
const isLogin = () => {
  return !!localStorage.getItem('token');
};
const getToken = () => {
  return localStorage.getItem('token');
};
const getRefreshToken = () => {
  return localStorage.getItem('refreshToken');
};
const setToken = token => {
  localStorage.setItem('token', token);
};
const getExpires = () => {
  return localStorage.getItem('expires_at');
};
const setExpires = val => {
  localStorage.setItem('expires_at', val);
};
const setRefreshToken = token => {
  localStorage.setItem('refreshToken', token);
};
const clearToken = () => {
  localStorage.removeItem('token');
};
const getInfo = () => {
  const user = localStorage.getItem('user');
  return JSON.parse(user || '{}');
};
const setInfo = user => {
  localStorage.setItem('user', JSON.stringify(user));
};
const clearInfo = () => {
  localStorage.removeItem('user');
};
export { setExpires, getExpires, isLogin, getToken, setToken, clearToken, setInfo, getInfo, clearInfo, setRefreshToken, getRefreshToken };
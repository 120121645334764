import auth from '@/utils/authentication';
import { useEffect, useMemo, useState } from 'react';
export const routes = [{
  name: '',
  key: 'visualization',
  children: [{
    name: '首页',
    key: 'base/home'
  }, {
    name: '详情',
    key: 'base/article'
  }]
}];
export const getName = (path, routes) => {
  return routes.find(item => {
    const itemPath = `/${item.key}`;
    if (path === itemPath) {
      return item.name;
    } else if (item.children) {
      return getName(path, item.children);
    }
  });
};
export const generatePermission = role => {
  const actions = role === 'admin' ? ['*'] : ['read'];
  const result = {};
  routes.forEach(item => {
    if (item.children) {
      item.children.forEach(child => {
        result[child.name] = actions;
      });
    }
  });
  return result;
};
const useRoute = userPermission => {
  const filterRoute = (routes, arr = []) => {
    if (!routes.length) {
      return [];
    }
    for (const route of routes) {
      const {
        requiredPermissions,
        oneOfPerm
      } = route;
      let visible = true;
      if (requiredPermissions) {
        visible = auth({
          requiredPermissions,
          oneOfPerm
        }, userPermission);
      }
      if (!visible) {
        continue;
      }
      if (route.children && route.children.length) {
        const newRoute = {
          ...route,
          children: []
        };
        filterRoute(route.children, newRoute.children);
        if (newRoute.children.length) {
          arr.push(newRoute);
        }
      } else {
        arr.push({
          ...route
        });
      }
    }
    return arr;
  };
  const [permissionRoute, setPermissionRoute] = useState(routes);
  useEffect(() => {
    const newRoutes = filterRoute(routes);
    setPermissionRoute(newRoutes);
  }, [JSON.stringify(userPermission)]);
  const defaultRoute = useMemo(() => {
    const first = permissionRoute[0];
    if (first) {
      const firstRoute = first?.children?.[0]?.key || first.key;
      return firstRoute;
    }
    return '';
  }, [permissionRoute]);
  return [permissionRoute, defaultRoute];
};
export default useRoute;